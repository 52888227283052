import React, { useEffect, useState } from 'react'


function BannerServices() {
  const [glassEffect, setGlassEffect] = useState(false);

  useEffect(() => {
    // Add glass effect after 1.5 seconds
    const timer = setTimeout(() => {
      setGlassEffect(true);
    }, 1500);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);
  return (
    <div className={`ServiceBanner mx-3 wow fadeInUp ${glassEffect ? 'glass-effect' : ''}`} data-wow-delay="1s">
      <div className="glass-overlay"></div> {/* Overlay for glass effect */}
      <div className='col-12 InsideBanner justify-content-center'>
      <div
      style={{
        height: '37px',
        width: '155px',
        color: 'white',
        border: '2px solid white',
        margin: 'auto',
        borderRadius: '37px',
        backgroundColor: 'transparent',
        fontSize:'1.1rem',
        fontWeight:'600',
      }}
      className="d-flex align-items-center justify-content-center wow fadeInUp " data-wow-delay="1.7s"
      >
        OUR SERVICE
      </div>
        <p className='wow fadeInUp mb-4' data-wow-delay="1.7s">
          Empowering Your Digital Transformation with Innovative Solution
        </p>
      </div>
    </div>
  )
}

export default BannerServices