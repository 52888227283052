import React, { useEffect, useState } from "react";

function AboutBanner() {
  const [glassEffect, setGlassEffect] = useState(false);

  useEffect(() => {
    // Add glass effect after 1.5 seconds
    const timer = setTimeout(() => {
      setGlassEffect(true);
    }, 1500);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);
  return (
    <div
      className={`ContactPageBanner Aboutbanner wow fadeInUp ${
        glassEffect ? "glass-effect" : ""
      }`}
      data-wow-delay="1s"
    >
      <div className="glass-overlay"></div>
      <div className="col-12 InsideBanner justify-content-center">
        <div
          style={{
            height: "37px",
            width: "155px",
            color: "white",
            border: "2px solid white",
            margin: "auto",
            borderRadius: "37px",
            backgroundColor: "transparent",
            fontSize: "1.1rem",
            fontWeight: "600",
          }}
          className="d-flex align-items-center justify-content-center wow fadeInUp mb-4"
          data-wow-delay="1.7s"
        >
          ABOUT US
        </div>
        <p className="wow fadeInUp my-0" data-wow-delay="1.7s">
          Crafting innovative software solutions for a competitive edge.
        </p>
      </div>
    </div>
  );
}

export default AboutBanner;
