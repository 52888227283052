import React from "react";
import { useNavigate } from "react-router-dom";

import {
  Genbg,
  Picon1,
  Picon10,
  Picon2,
  Picon3,
  Picon4,
  Picon5,
  Picon6,
  Picon7,
  Picon8,
  Picon9,
  Prop1,
  Prop10,
  Prop2,
  Prop3,
  Prop4,
  Prop5,
  Prop6,
  Prop7,
  Prop8,
  Prop9,
  genicon,
  sblue1,
  sblue2,
  sblue3,
  sblue4,
  sblue5,
  sblue6,
} from "../../assets";

function WePropel() {
  const navigate = useNavigate();

  const handleNavigate = (url) => {
    navigate(url);
  };

  return (
    <div className="WePropel wow fadeInUp" data-wow-delay="0.5s">
      <div className="col-12 Propel_content">
        <h2>
          At Vikgol, we propel your business with innovative digital services.
        </h2>

        <div className="row  Mobile_Margin mx-lg-5">
          <div
            className="col-md-5 px-3 mt-3"
            onClick={() =>
              handleNavigate(`/services/detail?ServiceType=web_app_development`)
            }
          >
            <div className="Design_box ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon1} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">Web App Development</h5>
                <p className="card-text">
                  We create innovative and scalable web applications tailored to
                  your business needs, providing one-stop solutions for clients
                  worldwide.
                </p>
              </div>

              <div className="m-2 mt-4">
                <img className="mobile_img5" src={Prop1} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-md-7 px-3 mt-3"
            onClick={() =>
              handleNavigate(
                `/services/detail?ServiceType=mobile_app_development`
              )
            }
          >
            <div className="Design_box ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon2} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">Mobile App Development</h5>
                <p className="card-text">
                  Transform and mobilize your business with our custom mobile
                  app development services, delivering high-performing apps
                  across various platforms.
                </p>
              </div>

              <div className="m-2 mt-5 ">
                <img className="mobile_img5" src={Prop2} alt="" />
              </div>
            </div>
          </div>

          <div
            className="col-md-4 px-3 mt-3"
            onClick={() =>
              handleNavigate(`/services/detail?ServiceType=devops`)
            }
          >
            <div className="Design_box Desbox ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon3} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">DevOps</h5>
                <p className="card-text">
                  Enhance collaboration and efficiency with our DevOps
                  solutions, ensuring agile, reliable, and high-performing
                  software delivery.
                </p>
              </div>
              <div className="m-2 mt-4">
                <img className="mobile_img5" src={Prop10} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-md-4 px-3 mt-3"
            onClick={() =>
              handleNavigate(`/services/detail?ServiceType=Ui-Ux-design`)
            }
          >
            <div className="Design_box Desbox ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon4} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">UI/UX Design</h5>
                <p className="card-text">
                  Our UI/UX designs combine creativity and functionality,
                  delivering user-centric interfaces that provide delightful and
                  intuitive experiences.
                </p>
              </div>
              <div className="m-2 mt-4">
                <img className="mobile_img5" src={Prop3} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-md-4 px-3 mt-3"
            onClick={() =>
              handleNavigate(`/services/detail?ServiceType=digital_marketing`)
            }
          >
            <div className="Design_box Desbox  ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon5} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">Digital Marketing</h5>
                <p className="card-text">
                  Boost your brand with our tailored digital marketing services,
                  leveraging data-driven insights to engage and convert your
                  target audience.
                </p>
              </div>
              <div className="m-2 mt-4">
                <img className="mobile_img5" src={Prop4} alt="" />
              </div>
            </div>
          </div>

          <div
            className="col-md-7 px-3 mt-3"
            onClick={() =>
              handleNavigate(`/services/detail?ServiceType=software_testing`)
            }
          >
            <div className="Design_box ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon6} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">Software Testing</h5>
                <p className="card-text">
                  Ensure impeccable performance and reliability with our
                  rigorous software testing services, guaranteeing your
                  software's quality and functionality.
                </p>
              </div>
              <div className="m-2 mt-5">
                <img className="mobile_img5" src={Prop5} alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-5 px-3 mt-3">
            <div className="Design_box ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon7} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">Blockchain Development</h5>
                <p className="card-text">
                  We create innovative and scalable web applications tailored to
                  your business needs, providing one-stop solutions for clients
                  worldwide.
                </p>
              </div>
              <div className="m-2 mt-4">
                <img className="mobile_img5" src={Prop6} alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-4 px-3 mt-3">
            <div className="Design_box Desbox  ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon8} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">AR & Mixed Reality Lab</h5>
                <p className="card-text">
                  Explore new dimensions with our AR and Mixed Reality Lab,
                  creating immersive experiences that redefine user engagement.
                </p>
              </div>
              <div className="m-2 mt-4">
                <img className="mobile_img5" src={Prop7} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3 mt-3">
            <div className="Design_box Desbox  ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon9} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">
                  Artificial Intelligence
                </h5>
                <p className="card-text">
                  Revolutionize your industry with our AI solutions, offering
                  advanced data-driven insights to enhance decision-making and
                  drive innovation.
                </p>
              </div>
              <div className="m-2 mt-4">
                <img className="mobile_img5" src={Prop8} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3 mt-3">
            <div className="Design_box Desbox ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={Picon10} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">Internet of Things</h5>
                <p className="card-text">
                  Harness the potential of a hyper-connected future with our IoT
                  services, from concept to implementation, optimizing
                  operations and connectivity.
                </p>
              </div>
              <div className="m-2 mt-4">
                <img className="mobile_img5" src={Prop9} alt="" />
              </div>
            </div>
          </div>
          <div
            className="col-md-4 px-3 mt-3"
            onClick={() =>
              handleNavigate(`/services/detail?ServiceType=Gen_AI`)
            }
          >
            <div className="Design_box Desbox ">
              <div className="card-body mt-4 px-lg-4">
                <div className="icon mb-3">
                  <img src={genicon} alt="" />
                </div>
                <h5 className="card-title mt-4 mb-3">Generative AI</h5>
                <p className="card-text">
                  Explore new dimensions with our AR and Mixed Reality Lab,
                  creating immersive experiences that redefine user engagement.
                </p>
              </div>
              <div className="m-2 mt-4">
                <img className="mobile_img5" src={Genbg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WePropel;
