import React, { useEffect, useState } from 'react';
import './Contact.scss'

function ContactBanner() {
    const [glassEffect, setGlassEffect] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setGlassEffect(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

    return (
        <div className= {`ContactPageBanner wow fadeInUp ${glassEffect ? 'glass-effect' : ''}`} data-wow-delay="1s" >
            <div className="glass-overlay"></div>
            <div className='col-12 InsideBanner justify-content-center'>
            <div
      style={{
        height: '37px',
        width: '155px',
        color: 'white',
        border: '2px solid white',
        margin: 'auto',
        borderRadius: '37px',
        backgroundColor: 'transparent',
        fontSize:'1.1rem',
        fontWeight:'600',
      }}
      className="d-flex align-items-center justify-content-center wow fadeInUp mb-4" data-wow-delay="1.7s"
      >
        CONTACT US
      </div>
                <p className='wow fadeInUp' data-wow-delay="1.7s" >
                    Have questions or need assistance?
                    Our team is here to help
                </p>

            </div>
        </div>
    )
}

export default ContactBanner