import React from 'react';
import { Nextb, Trans1, Trans2, Trans3, Trans4,next } from '../../assets';

const TransformingIdeasSection = () => {
  return (
    <section className="transforming-ideas-section text-center py-5">
      <div className="container wow fadeInUp" data-wow-delay ="0.5s" >
        <h2 className="mb-4">Turning concepts into tangible outcomes.</h2>
        <p className="mb-5 weTrans">We've transformed challenges into triumphs, crafting digital symphonies that redefine industries.</p>
        <div className="row">
          <div className="col-md-3 mb-4">
            <div className="card h-100">
              <div className="card-body py-lg-5">
                <div className="icon mb-3">
                <img src={Trans1} alt="" />
                </div>
                <h5 className="card-title">Align your vision</h5>
                <p className="card-text">Let's start with a clear understanding of your goals. Our initial conversation will help us tailor a solution that perfectly fits your needs.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="card h-100">
              <div className="card-body py-lg-5">
                <div className="icon mb-3">
                <img src={Trans2} alt="" />
                </div>
                <h5 className="card-title">Build your dream team</h5>
                <p className="card-text">We'll craft a dedicated team aligned with your project's specifics. Together, we'll turn your ideas into reality.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="card h-100">
              <div className="card-body py-lg-5">
                <div className="icon mb-3">
                <img src={Trans3} alt="" />
                </div>
                <h5 className="card-title">Launch and grow</h5>
                <p className="card-text">Our support doesn't end at launch. We'll guide you through the initial stages and optimize your project for continued success.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="card h-100">
              <div className="card-body py-lg-5">
                <div className="icon mb-3">
                <img src={Trans4} alt="" />
                </div>
                <h5 className="card-title">Your trust, our commitment</h5>
                <p className="card-text">Confidentiality is paramount. We protect your ideas with a robust NDA.</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="mt-5 ">
            <p className='my-3 text_BelowTrans'>
            Are you ready to transform your ideas into reality?
            </p>

            <a target='_blank' href="https://calendly.com/harsh-46/30min?month=2024-08" className="btn btn-primary btn-visonimpout mt-5 m-auto  btn-lg"><span>Contact us</span> <img className='mt-1 imgNext' src={Nextb} alt="" /></a>
        </div>
      </div>
    </section>
  );
};

export default TransformingIdeasSection;