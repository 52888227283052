import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.scss'
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import { Insta, LOGO, Linkedin, Mail, Pin, fb, twitter } from '../../assets';
import { NavLink } from 'react-router-dom';


const Footer = () => {
    return (
        <footer className="FooterStyle pt-5">
            <div className="container">
                <div className="row">
                    <div className=" LogoStyle mb-5">
                        <img src={LOGO} alt="Vikgol" />
                    </div>
                    <div className="col-md-4">
                        <p className='footerdes'>Your trusted partner in digital innovation. We deliver exceptional software solutions tailored to your business needs. Contact us today to explore how we can help you achieve your goals.</p>
                        </div>
                    <div className="col-md-2">
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled routlist">
                            <li><NavLink to="/" exact activeClassName="active">Home</NavLink></li>
                            <li><NavLink to="/about-us" activeClassName="active">About Us</NavLink></li>
                            <li><NavLink to="/contact-us" activeClassName="active">Contact</NavLink></li>
                            <li><NavLink to="/blog" activeClassName="active">Blog</NavLink></li>

                            <li><NavLink to="/careers" activeClassName="active">Careers</NavLink></li>

                        </ul>

                    </div>
                   
                    <div className="col-md-3">
                        <h5>Services</h5>
                        <ul className="list-unstyled">
                            <li><NavLink to="/services/detail?ServiceType=mobile_app_development">Mobile App Development</NavLink></li>
                            <li><NavLink to="/services/detail?ServiceType=web_app_development" >Web App Development</NavLink></li>
                            <li><NavLink to="/services/detail?ServiceType=Ui-Ux-design" >UI/UX Design</NavLink></li>
                            <li><NavLink to="/services/detail?ServiceType=devops" >DevOps</NavLink></li>
                            <li><NavLink to="/services/detail?ServiceType=digital_marketing" >Digital Marketing</NavLink></li>
                            <li><NavLink to="/services/detail?ServiceType=software_testing" >Software Testing</NavLink></li>
                            <li><NavLink to="/services/detail?ServiceType=Gen_AI" >Generative AI</NavLink></li>
                        </ul>

                    </div>
                    <div className="col-md-3">
                        <h5>Contact Info</h5>
                        <ul className="list-unstyled">
                            <li><a href="mailto:contact@vigkol.com" > <img src={Mail} alt="Vikgol" />  contact@vikgol.com</a></li>
                            <li><a href="/location" >      <img src={Pin} alt="Vikgol" />  #265, 2nd Floor, 6th main 4th Cross, 2nd Stage, BTM Layout, Bengaluru – 560076</a></li>
                        </ul>
                      
                    </div>
                    <div className="col-md-4 mt-3">
                    <p className='footerdes' >© 2018-2024 Vikgol. All rights reserved.</p>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center mt-3">
                    <div className=" row ">
                            <a href="https://www.facebook.com/vikgols/" className="socialIcon me-2">
                                <img src={fb} alt="Facebook" />
                            </a>
                            <a href="https://www.instagram.com/vikgol/" className="socialIcon me-2">
                                <img src={Insta} alt="Instagram" />
                            </a>
                            <a href="https://x.com/vikgols?lang=en" className="socialIcon x-app me-2">
                                <img src={twitter} alt="Twitter" />
                            </a>
                            <a href="https://in.linkedin.com/company/vikgol" className="socialIcon">
                                <img src={Linkedin} alt="LinkedIn" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4 privacyroutes mt-3">
                    <a><NavLink to="/privacyPolicy" activeClassName="active">Privacy Policy</NavLink></a>
                            <a><NavLink to="/termsOfService" activeClassName="active">Terms of Service</NavLink></a>
                    </div>
                   

                </div>


            </div>

            <div className="w-100 mt-5  row justify-content-center">



                <div className='Small-box col-md-3'>

                </div>


                <div className='Big-box col-md-12 d-flex justify-content-center'>

                </div>

            </div>

        </footer>
    );
};

export default Footer;
