import React from "react";
import { VkCh1, VkCh2, VkCh3, VkCh4, VkCh5, VkCh6 } from "../../assets";

const ChooseVikgole = () => {
  return (
    <div className="chooseVikgole wow fadeInUp" data-wow-delay="0.5s">
      <h1>Why choose vikgol</h1>

      <div className="row mt-lg-4">
        <div className="col-md-4">
          <div className="ChooseVikgoleDesign">
            <img style={{height:'39px'}} src={VkCh6} alt="" />

            <h3>Expertise and innovation</h3>
            <p>
              Enhance collaboration and efficiency with our DevOps solutions,
              ensuring agile, reliable, and high-performing software delivery.
            </p>

            <img className="img-Det" src={VkCh3} alt="" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="ChooseVikgoleDesign">
            <img src={VkCh1} alt="" />

            <h3>Customer focused</h3>

            <p>
              Our UI/UX designs combine creativity and functionality, delivering
              user-centric interfaces that provide delightful and intuitive
              experiences.
            </p>

            <img className="img-Det" src={VkCh4} alt="" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="ChooseVikgoleDesign">
            <img src={VkCh2} alt="" />

            <h3>Quality and reliability</h3>

            <p>
              Boost your brand with our tailored digital marketing services,
              leveraging data-driven insights to engage and convert your target
              audience.
            </p>

            <img className="img-Det" src={VkCh5} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseVikgole;
